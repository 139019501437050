import React from 'react';
import styled from 'styled-components';
import HeaderContent from './HeaderContent';
import Menu from './Menu/Menu';
import Particles from './Particles';
import { MOBILE_VIEW_PORT } from '../constants/styles';

type Props = {
  isSticky: boolean;
};

type StyledProps = Pick<Props, 'isSticky'>;

export const HEADER_DEFAULT_HEIGHT = '220px';
export const HEADER_FOLDED_HEIGHT = '60px';

const Header = (props: Props) => {
  const { isSticky } = props;
  return (
    <StyledHeader isSticky={isSticky}>
      <MenuWrapper>
        <Menu />
      </MenuWrapper>
      <ContentWrapper>
        <HeaderContent isSticky={isSticky} />
      </ContentWrapper>
      <ParticlesWrapper isSticky={isSticky}>
        <Particles />
      </ParticlesWrapper>
    </StyledHeader>
  );
};

const StyledHeader = styled.header<StyledProps>`
  position: fixed;
  width: 100%;
  height: ${props => (props.isSticky ? HEADER_FOLDED_HEIGHT : HEADER_DEFAULT_HEIGHT)};
  box-shadow: ${props => (props.isSticky ? '0 1px 5px rgba(0, 0, 0, 0.3)' : 'none')};
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  transition: height 0.3s ease-out;

  @media (max-width: ${MOBILE_VIEW_PORT}) {
    height: ${HEADER_FOLDED_HEIGHT};
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }
`;

const MenuWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  z-index: 1000;

  @media (max-width: ${MOBILE_VIEW_PORT}) {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const ParticlesWrapper = styled.div<StyledProps>`
  visibility: ${props => (props.isSticky ? 'hidden' : 'visible')};
  opacity: ${props => (props.isSticky ? 0 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: opacity 0.3s ease-out 0.1s;

  @media (max-width: ${MOBILE_VIEW_PORT}) {
    display: none;
  }
`;

export default Header;
