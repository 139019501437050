import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { rhythm } from '../../utils/typography';

type Props = {
  name: string;
  ruby: string;
  onClick: Function;
};

const MenuLink = (props: Props) => {
  const { name, ruby, onClick } = props;
  const navigateToCategory = useCallback(() => {
    navigate(`/category/${ruby.toLowerCase()}`);
    onClick();
  }, []);
  return (
    <Wrapper onClick={navigateToCategory}>
      <Name>{name}</Name>
      <Ruby>{ruby}</Ruby>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: ${rhythm(0.5)} 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
`;

const Name = styled.div`
  font-size: ${rhythm(0.6)};
  font-weight: 600;
  color: rgb(51, 51, 51);
`;

const Ruby = styled.div`
  font-size: ${rhythm(0.5)};
  color: rgba(51, 51, 51, 0.5);
  line-height: 1;
`;

export default MenuLink;
