import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants/styles';
import MenuBar from './MenuBar';
import MenuLink from './MenuLink';

const menus = [
  {
    name: 'エンジニアリング',
    ruby: 'ENGINEERING'
  },
  {
    name: 'デザイン',
    ruby: 'DESIGN'
  },
  {
    name: 'カルチャー',
    ruby: 'CULTURE'
  }
];

const Menu = () => {
  const [isOpened, setIsOpened] = useState(false);
  const handleClick = useCallback(() => {
    setIsOpened(_isOpened => !_isOpened);
  }, []);
  return (
    <Wrapper>
      <MenuBar isOpened={isOpened} onClick={handleClick} />
      <Content isOpened={isOpened}>
        {menus.map(({ name, ruby }) => (
          <MenuLink key={name} name={name} ruby={ruby} onClick={handleClick} />
        ))}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div<{ isOpened: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  top: 0;
  left: 0;
  margin-top: 60px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid ${PRIMARY_COLOR};
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: ${props => (props.isOpened ? 1 : 0)};
  transform: ${props => (props.isOpened ? 'translateX(0)' : 'translateX(-100%)')};
`;

export default Menu;
