import React from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR, TEXT_COLOR } from '../constants/styles';
import { rhythm } from '../utils/typography';

const Footer = () => (
  <Wrapper>
    <LinkList>
      <LinkItem>
        <Link href="https://trista.jp/contact/">お問い合わせ</Link>
      </LinkItem>
      <LinkItem>
        <Link href="https://trista.jp/">運営会社</Link>
      </LinkItem>
    </LinkList>
    <Copyright>&copy; TRISTA Co., Ltd.</Copyright>
  </Wrapper>
);

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: ${rhythm(1)} ${rhythm(1)} ${rhythm(3)};
  text-align: center;
`;

const LinkList = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
`;

const LinkItem = styled.li`
  margin: 0 ${rhythm(0.5)} 0 0;
  font-size: ${rhythm(0.5)};

  &:last-of-type {
    margin: 0;
  }
`;

const Link = styled.a`
  color: ${TEXT_COLOR};
  transition: color 0.2s ease-out;

  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

const Copyright = styled.small`
  font-size: ${rhythm(0.5)};
`;

export default Footer;
