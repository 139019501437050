import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Post, { PostProps } from './Post';
import { rhythm } from '../utils/typography';

export type PostListProps = readonly {
  post: PostProps & { id: string };
}[];

type Props = {
  posts: PostListProps;
};

const PostList = (props: Props) => {
  const { posts } = props;
  return (
    <StyledGrid fluid>
      <Row>
        {posts.map(({ post }) => (
          <StyledCol key={post.id} xs={12} sm={6} md={4}>
            <Post post={post} />
          </StyledCol>
        ))}
      </Row>
    </StyledGrid>
  );
};

export const query = graphql`
  fragment PostListFragment on MarkdownRemarkEdge {
    post: node {
      id
      ...PostFragment
    }
  }
`;

const StyledGrid = styled(Grid)`
  padding: 0 !important;
`;

const StyledCol = styled(Col)`
  margin-bottom: ${rhythm(0.75)};
`;

export default PostList;
