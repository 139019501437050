import React from 'react';
import designer from './designer.svg';

type Props = {
  size: number;
};

const Designer = (props: Props) => {
  const { size } = props;
  return <img src={designer} alt="デザイナー" width={size} height={size} />;
};

Designer.defaultProps = {
  size: 100
};

export default Designer;
