import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { PRIMARY_COLOR } from '../../constants/styles';

type Props = {
  isOpened: boolean;
  onClick: () => void;
};

type StyledProps = Pick<Props, 'isOpened'>;

const MenuBar = (props: Props) => {
  const { isOpened, onClick } = props;
  const handleTouchEnd = useCallback((e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  }, []);
  return (
    <Wrapper onClick={onClick} onTouchEnd={handleTouchEnd}>
      <Bar isOpened={isOpened} />
      <Bar isOpened={isOpened} />
      <Bar isOpened={isOpened} />
    </Wrapper>
  );
};

const openTopBar = keyframes`
  0% {
    transform: translateY(9px) rotate(45deg);
  }

  50% {
    transform: translateY(9px) rotate(0);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
`;

const openBottomBar = keyframes`
  0% {
    transform: translateY(-9px) rotate(-45deg);
  }

  50% {
    transform: translateY(-9px) rotate(0);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
`;

const closeTopBar = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(9px) rotate(0);
  }

  100% {
    transform: translateY(9px) rotate(45deg);
  }
`;

const closeBottomBar = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(-9px) rotate(0);
  }

  100% {
    transform: translateY(-9px) rotate(-45deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 21px;
  cursor: pointer;
`;

const Bar = styled.span<StyledProps>`
  display: inline-block;
  width: 100%;
  height: 3px;
  background-color: ${PRIMARY_COLOR};
  animation-duration: 0.75s;
  animation-fill-mode: forwards;

  &:nth-of-type(1) {
    animation-name: ${props => (props.isOpened ? closeTopBar : openTopBar)};
  }

  &:nth-of-type(2) {
    opacity: ${props => (props.isOpened ? 0 : 1)};
    transition: opacity 0.25s ease-out;
  }

  &:nth-of-type(3) {
    animation-name: ${props => (props.isOpened ? closeBottomBar : openBottomBar)};
  }
`;

export default MenuBar;
