import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Heading from './Heading';
import TagLink from './TagLink';
import TagIcon from '../icons/Tag/Tag';
import { rhythm } from '../utils/typography';

export type TagProps = {
  name: string;
  totalCount?: number;
};

type Props = {
  tags: readonly TagProps[];
};

const TagList = (props: Props) => {
  const { tags } = props;
  return (
    <Wrapper>
      <Heading icon={<TagIcon alt="タグ一覧" />}>タグ一覧</Heading>
      <Tags>
        {tags.map(({ name, totalCount }) => (
          <TagLink key={name} name={name} count={totalCount} />
        ))}
      </Tags>
    </Wrapper>
  );
};

export const query = graphql`
  fragment TagListFragment on MarkdownRemarkGroupConnection {
    name: fieldValue
    totalCount
  }
`;

const Wrapper = styled.div`
  padding-top: ${rhythm(1)};
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${rhythm(0.4)};
`;

export default TagList;
