import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { SiteMetaDataProps, SiteProps } from './Ogp';

type Props = {
  metadata: SiteProps<Pick<SiteMetaDataProps, 'title'>>;
};

const Meta = (props: Props) => {
  const { metadata } = props;
  return (
    <Helmet>
      <html lang="ja" prefix="og: http://ogp.me/ns#" />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={metadata.siteMetadata.title} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href="/img/favicon.ico" />
    </Helmet>
  );
};

export const query = graphql`
  fragment MetaFragment on Site {
    siteMetadata {
      title
    }
  }
`;

export default Meta;
