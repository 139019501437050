import React, { useEffect } from 'react';
import styled from 'styled-components';
import particles from '../constants/particles';

interface ParticlesWindow extends Window {
  particlesJS: (id: string, options: { [s: string]: unknown }) => void;
}
declare const window: ParticlesWindow;

const PARTICLES_ID = 'particles';

const Particles = () => {
  useEffect(() => {
    const importParticles = async () => {
      // build時にwindowが参照できないため、render前に読み込む
      await import('particles.js');
      const { particlesJS } = window;
      if (particlesJS != null) {
        particlesJS(PARTICLES_ID, particles);
      }
    };
    importParticles();
  }, []);
  return <Wrapper id={PARTICLES_ID} />;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default Particles;
