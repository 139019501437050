import React from 'react';
import styled from 'styled-components';
import tag from './tag.svg';

type Props = {
  alt: string;
  size: number;
};

const Tag = (props: Props) => {
  const { alt, size } = props;
  return <Img src={tag} alt={alt} width={size} height={size} />;
};

Tag.defaultProps = {
  alt: '',
  size: 34
};

const Img = styled.img`
  margin: 0;
`;

export default Tag;
