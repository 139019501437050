export default {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: '#81efc5'
    },
    shape: {
      type: ['circle'],
      stroke: {
        width: 0
      }
    },
    opacity: {
      value: 0.5,
      random: true,
      anim: {
        enable: false
      }
    },
    size: {
      value: 8,
      random: true,
      anim: {
        enable: false
      }
    },
    line_linked: {
      enable: true,
      color: '#e0fff0'
    },
    move: {
      enable: true,
      speed: 1,
      random: true,
      straight: false,
      out_mode: 'bounce',
      bounce: false,
      attract: {
        enable: false
      }
    }
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false
      },
      onclick: {
        enable: false
      },
      resize: true
    }
  },
  retina_detect: false
};
