import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { PRIMARY_COLOR, TEXT_COLOR } from '../constants/styles';
import DesignerIcon from '../icons/Designer/Designer';
import DirectorIcon from '../icons/Director/Director';
import EngineerIcon from '../icons/Engineer/Engineer';
import { rhythm } from '../utils/typography';

const links = [
  {
    icon: <EngineerIcon />,
    alt: 'エンジニア',
    href: 'https://trista.jp/recruit/'
  },
  {
    icon: <DirectorIcon />,
    alt: '企画・ディレクター',
    href: 'https://trista.jp/recruit/'
  },
  {
    icon: <DesignerIcon />,
    alt: 'デザイナー',
    href: 'https://trista.jp/recruit/'
  }
];

const RecruitLinks = () => (
  <Wrapper>
    <Content>
      <Heading>
        <HeadingContent>採用情報</HeadingContent>
      </Heading>
      <Grid fluid>
        <Row>
          {links.map(({ icon, alt, href }) => (
            <Col key={alt} xs={12} sm={6} md={4}>
              <LinkWrapper>
                {icon}
                <Link href={href}>
                  <LinkContent>{alt}</LinkContent>
                </Link>
              </LinkWrapper>
            </Col>
          ))}
        </Row>
      </Grid>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${rhythm(1)};
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const Content = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 ${rhythm(0.75)};
`;

const HeadingContent = styled.h4`
  margin: 0;
  padding-bottom: 10px;
  flex-shrink: 0;
  color: #555;
  background: linear-gradient(transparent 85%, ${PRIMARY_COLOR} 0%);
`;

const LinkWrapper = styled.div`
  padding: ${rhythm(0.5)} 0;
  text-align: center;
  line-height: 0;

  img {
    margin: 0;
  }
`;

const Link = styled.a`
  margin-right: ${rhythm(0.5)};
  font-size: ${rhythm(0.6)};
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.6;
  }
`;

const LinkContent = styled.h6`
  margin: ${rhythm(0.5)} ${rhythm(0.5)} 0;
  padding: ${rhythm(0.6)} 0;
  background-color: #fff;
  color: ${TEXT_COLOR};
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${PRIMARY_COLOR};
    color: #fff;
  }
`;

export default RecruitLinks;
