import React from 'react';
import styled from 'styled-components';
import CategoryLink from './CategoryLink';
import { MOBILE_VIEW_PORT } from '../constants/styles';

type Props = {
  isSticky: boolean;
};

type StyledProps = Pick<Props, 'isSticky'>;

const categories = ['engineering', 'design', 'culture'];

const CategoryList = (props: Props) => {
  const { isSticky } = props;
  return (
    <CategoryWrapper isSticky={isSticky}>
      {categories.map(category => (
        <Category key={category}>
          <CategoryLink isSticky={isSticky} name={category} />
        </Category>
      ))}
    </CategoryWrapper>
  );
};

const CategoryWrapper = styled.ul<StyledProps>`
  max-width: ${props => (props.isSticky ? '450px' : '680px')};
  right: ${props => (props.isSticky ? 0 : '50%')};
  transform: ${props => `translateX(${props.isSticky ? 0 : 50}%)`};
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 80px;
  margin: 0;
  font-size: 0.8rem;
  list-style: none;
  z-index: 10;

  @media (max-width: ${MOBILE_VIEW_PORT}) {
    display: none;
  }
`;

const Category = styled.li`
  width: 33%;
  height: 100%;
  margin: 0;
`;

export default CategoryList;
