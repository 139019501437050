import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Heading from './Heading';
import ArchiveIcon from '../icons/Archive/Archive';
import { PRIMARY_COLOR, TEXT_COLOR } from '../constants/styles';
import { rhythm } from '../utils/typography';

export type ArchiveProps = {
  archive: {
    context: {
      date: string;
    };
  };
};

type Props = {
  archives: readonly ArchiveProps[];
};

const ArchiveLinks = ({ archives }: Props) => (
  <Wrapper>
    <Heading icon={<ArchiveIcon alt="アーカイブ" />}>アーカイブ</Heading>
    <LinkList>
      {archives.map(({ archive }) => {
        const { date } = archive.context;
        return (
          <li key={date}>
            <StyledLink to={`/archive/${date}`}>{date}</StyledLink>
          </li>
        );
      })}
    </LinkList>
  </Wrapper>
);

export const query = graphql`
  fragment ArchiveLinksFragment on SitePageEdge {
    archive: node {
      context {
        date
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: ${rhythm(1)};
`;

const LinkList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0 ${rhythm(0.5)};
  list-style: none;
`;

const StyledLink = styled(Link)`
  margin-right: ${rhythm(0.5)};
  font-size: ${rhythm(0.6)};
  color: ${TEXT_COLOR};
  transition: color 0.2s ease-out;

  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

export default ArchiveLinks;
