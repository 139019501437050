import React from 'react';
import styled from 'styled-components';
import logo from './logo.svg';

type Props = {
  size: number;
};

type StyledProps = Pick<Props, 'size'>;

const Logo = (props: Props) => {
  const { size } = props;
  return <Img src={logo} size={size} alt="トリスタinside" />;
};

Logo.defaultProps = {
  size: 40
};

const Img = styled.img<StyledProps>`
  max-height: ${props => `${props.size}px`};
  margin: 0;
  transition: transform 0.3s ease-out;
`;

export default Logo;
