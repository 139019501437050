import React from 'react';
import styled from 'styled-components';
import { rhythm } from '../utils/typography';

type Props = {
  icon: React.ReactNode;
  children: React.ReactNode;
};

const Heading = (props: Props) => {
  const { icon, children } = props;
  return (
    <Wrapper>
      {icon}
      <Content>{children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${rhythm(0.75)};
`;

const Content = styled.h4`
  margin: 0 0 0 ${rhythm(0.125)};
  color: #555;
`;

export default Heading;
