import React from 'react';
import styled from 'styled-components';
import arrow from './arrow.svg';

type Props = {
  size: number;
};

const Arrow = (props: Props) => {
  const { size } = props;
  return <Img alt="TOPへ戻る" src={arrow} width={size} height={size} />;
};

Arrow.defaultProps = {
  size: 32
};

const Img = styled.img`
  margin: 0;
`;

export default Arrow;
