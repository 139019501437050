import { graphql, StaticQuery } from 'gatsby';
import 'prismjs/themes/prism.css';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MOBILE_VIEW_PORT } from '../constants/styles';
import GlobalStyle from '../utils/GlobalStyle';
import useScrollTop from '../utils/react/useScrollTop';
import { rhythm } from '../utils/typography';
import ArchiveLinks, { ArchiveProps } from './ArchiveLinks';
import BackToTop from './BackToTop';
import Footer from './Footer';
import Header, { HEADER_DEFAULT_HEIGHT, HEADER_FOLDED_HEIGHT } from './Header';
import Meta from './Meta';
import { SiteProps } from './Ogp';
import RecruitLinks from './RecruitLinks';
import TagList, { TagProps } from './TagList';

type Props = {
  data: {
    site: SiteProps;
    allMarkdownRemark: {
      tags: readonly TagProps[];
    };
    allSitePage: {
      archives: readonly ArchiveProps[];
    };
  };
  children: React.ReactNode;
};

const TARGET_ID = 'app-top';

const Layout = (props: Props) => {
  const { data, children } = props;
  const scrollTop = useScrollTop();
  const isHeaderSticky = useMemo(() => scrollTop > 10, [scrollTop]);
  const isBackToTopVisible = useMemo(() => scrollTop > 100, [scrollTop]);
  return (
    <Wrapper id={TARGET_ID}>
      <GlobalStyle />
      <Meta metadata={data.site} />
      <Header isSticky={isHeaderSticky} />
      <Main>
        <MainContent>
          {children}
          <TagList tags={data.allMarkdownRemark.tags} />
          <ArchiveLinks archives={data.allSitePage.archives} />
        </MainContent>
      </Main>
      <RecruitLinks />
      <Footer />
      <BackToTop isVisible={isBackToTopVisible} targetId={TARGET_ID} />
    </Wrapper>
  );
};

const query = graphql`
  query LayoutQuery {
    site {
      ...MetaFragment
    }
    allMarkdownRemark {
      tags: group(field: frontmatter___tags) {
        ...TagListFragment
      }
    }
    allSitePage(
      filter: { path: { regex: "/^/archive/.*/" } }
      sort: { fields: [path], order: DESC }
    ) {
      archives: edges {
        ...ArchiveLinksFragment
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #f8f8f8;
`;

const Main = styled.main`
  max-width: 1136px;
  padding-top: ${HEADER_DEFAULT_HEIGHT};
  margin: 0 auto;

  @media (max-width: ${MOBILE_VIEW_PORT}) {
    padding-top: ${HEADER_FOLDED_HEIGHT};
  }
`;

const MainContent = styled.div`
  padding: ${rhythm(1)};
`;

export default (props: Pick<Props, 'children'>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StaticQuery query={query} render={data => <Layout data={data} {...props} />} />
);
