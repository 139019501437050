import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: visible;
  }

  body {
    font-family: 'Quattrocento Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'Meiryo', 'メイリオ', sans-serif;
  }

  a {
    background-image: none;
    text-shadow: none;
    cursor: pointer;
    outline: none;
  }

  iframe {
    margin: 0;
  }

  .footnotes p {
    display: inline;
  }
`;

export default GlobalStyle;
