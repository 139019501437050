import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR, TEXT_COLOR } from '../constants/styles';
import { rhythm } from '../utils/typography';

type Props = {
  name: string;
  count?: number;
};

const isNumber = (count?: number): count is number => count != null && !Number.isNaN(count);

const TagLink = (props: Props) => {
  const { name, count } = props;
  return (
    <StyledLink to={`/tag/${kebabCase(name)}`}>
      <Name hasCount={isNumber(count)}>{name}</Name>
      {isNumber(count) && <Counter>{count}</Counter>}
    </StyledLink>
  );
};

const Name = styled.span<{ hasCount: boolean }>`
  display: inline-block;
  padding: ${props => (props.hasCount ? '3px 8px 3px 15px' : '3px 15px')};
  background-color: #f0f0f0;
  border-radius: ${props => (props.hasCount ? '25px 0 0 25px' : '25px')};
  transition: background-color 0.2s ease-out;
`;

const Counter = styled.span`
  display: inline-block;
  padding: 3px 12px 3px 8px;
  background-color: #e6e6e6;
  border-radius: 0 25px 25px 0;
  transition: background-color 0.2s ease-out;
`;

const StyledLink = styled(Link)`
  margin: 0 ${rhythm(0.5)} ${rhythm(0.5)} 0;
  font-size: ${rhythm(0.5)};
  color: ${TEXT_COLOR};
  border-radius: 25px;
  opacity: 1;

  &:hover {
    color: #fff;

    /* stylelint-disable-next-line */
    ${Name} {
      background-color: ${PRIMARY_COLOR};
    }

    /* stylelint-disable-next-line */
    ${Counter} {
      background-color: #45a793;
    }
  }
`;

export default TagLink;
