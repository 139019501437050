import throttle from 'lodash.throttle';
import { useCallback, useEffect, useState } from 'react';

const THROTTLE_INTERVAL_MS = 500;

const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const handleScroll = useCallback(
    throttle(() => {
      const { documentElement, body } = document;
      let scroll = 0;
      if (documentElement != null && documentElement.scrollTop > 0) {
        scroll = documentElement.scrollTop;
      } else if (body != null && body.scrollTop > 0) {
        scroll = body.scrollTop;
      }
      setScrollTop(scroll);
    }, THROTTLE_INTERVAL_MS),
    []
  );
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return scrollTop;
};

export default useScrollTop;
