import { graphql, Link } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DEFAULT_OGP_IMAGE } from '../constants/src';
import { TEXT_COLOR } from '../constants/styles';
import { rhythm } from '../utils/typography';

export type PostProps = {
  frontmatter: {
    date: string;
    daysElapsed: number;
    ogp: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    tags: string[];
    title: string;
  };
  fields: {
    slug: string;
  };
};

type Props = {
  post: PostProps;
};

const Post = (props: Props) => {
  const { post } = props;
  const isNew = post.frontmatter.daysElapsed <= 7;
  const image = useMemo(() => {
    const { ogp } = post.frontmatter;
    const style: React.CSSProperties = {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    };
    if (ogp != null) {
      return <Img fixed={ogp.childImageSharp.fixed} style={style} />;
    }
    return <img src={DEFAULT_OGP_IMAGE} alt="" style={style} />;
  }, []);
  return (
    <Wrapper>
      <PostLink to={post.fields.slug}>
        {isNew && <NewLabel />}
        <ImageWrapper>{image}</ImageWrapper>
        <Content>
          <Date>{post.frontmatter.date}</Date>
          <Title>{post.frontmatter.title}</Title>
          <Tags>
            {post.frontmatter.tags.slice(0, 2).map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Tags>
        </Content>
      </PostLink>
    </Wrapper>
  );
};

export const query = graphql`
  fragment PostFragment on MarkdownRemark {
    frontmatter {
      date(formatString: "YYYY.MM.DD")
      daysElapsed: date(difference: "days")
      ogp {
        childImageSharp {
          fixed(width: 600) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      tags
      title
    }
    fields {
      slug
    }
  }
`;

const Wrapper = styled.article`
  position: relative;
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-out;

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
`;

const NewLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: -8px -8px 0 0;
  padding: ${rhythm(0.25)} ${rhythm(0.5)};
  line-height: 1;
  background-color: #f04;
  z-index: 999;

  &::before {
    content: 'NEW';
    color: #fff;
    letter-spacing: 1px;
    font-size: ${rhythm(0.5)};
  }
`;

const PostLink = styled(Link)`
  color: inherit;
  background-image: none;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 2px 2px 0 0;

  &::before {
    content: '';
    display: block;
    margin-top: 56.25%;
  }
`;

const Content = styled.div`
  padding: ${rhythm(0.5)};
`;

const Date = styled.div`
  font-size: ${rhythm(0.5)};
  color: rgba(0, 0, 0, 0.5);
`;

const Title = styled.h4`
  height: 2.1rem;
  margin: ${rhythm(0.25)} 0 0;
  overflow: hidden;
`;

const Tags = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  margin: ${rhythm(0.5)} 0 0;
  font-size: ${rhythm(0.5)};
  list-style: none;
`;

const Tag = styled.li`
  margin: 0 ${rhythm(0.5)} 0 0;
  padding: 3px 15px;
  font-size: ${rhythm(0.5)};
  color: ${TEXT_COLOR};
  background-color: #f0f0f0;
  border-radius: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default Post;
