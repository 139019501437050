import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import CategoryList from './CategoryList';
import { MOBILE_VIEW_PORT } from '../constants/styles';
import LogoIcon from '../icons/Logo/Logo';

type Props = {
  isSticky: boolean;
};

type StyledProps = Pick<Props, 'isSticky'>;

const HeaderContent = (props: Props) => {
  const { isSticky } = props;
  return (
    <Wrapper>
      <LogoWrapper isSticky={isSticky}>
        <Link to="/">
          <LogoIcon />
        </Link>
      </LogoWrapper>
      <CategoryList isSticky={isSticky} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div<StyledProps>`
  padding-bottom: ${props => (props.isSticky ? 0 : '40px')};
  left: ${props => (props.isSticky ? 0 : '50%')};
  /* stylelint-disable-next-line */
  transform: ${props =>
    props.isSticky ? `translate(0, -50%) scale(0.75)` : `translate(-50%, -50%) scale(1)`};
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out 0.1s, left 0.4s ease-out 0.1s;
  line-height: 0;
  z-index: 1;

  @media (max-width: ${MOBILE_VIEW_PORT}) {
    left: 50%;
    padding-bottom: 0;
    transform: translate(-50%, -50%) scale(0.75);
  }
`;

export default HeaderContent;
