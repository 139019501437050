import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../constants/styles';
import { rhythm } from '../utils/typography';

type Props = {
  isSticky: boolean;
  name: string;
};

type StyledProps = Pick<Props, 'isSticky'>;

export const convertCategoryName = (name: string): string => {
  if (name === 'engineering') {
    return 'エンジニアリング';
  }
  if (name === 'design') {
    return 'デザイン';
  }
  if (name === 'culture') {
    return 'カルチャー';
  }
  return '';
};

const CategoryLink = (props: Props) => {
  const { isSticky, name } = props;
  return (
    <Link to={`/category/${name}`}>
      <Wrapper>
        <LinkContent isSticky={isSticky}>
          <Name>{convertCategoryName(name)}</Name>
          <Ruby>{name.toUpperCase()}</Ruby>
        </LinkContent>
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 2px;
  border-bottom: 2px solid transparent;
  transition: border 0.3s ease-out;

  &:hover {
    border-bottom: 2px solid ${PRIMARY_COLOR};
  }
`;

const LinkContent = styled.div<StyledProps>`
  transform: ${props => `scale(${props.isSticky ? 0.9 : 1})`};
  transition: transform 0.3s ease-out;
`;

const Name = styled.div`
  font-size: ${rhythm(0.6)};
  font-weight: 600;
  color: rgb(51, 51, 51);
`;

const Ruby = styled.div`
  font-size: ${rhythm(0.5)};
  color: rgba(51, 51, 51, 0.5);
`;

export default CategoryLink;
