import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

export type SiteProps<T extends {} = SiteMetaDataProps> = {
  siteMetadata: T;
};

export type SiteMetaDataProps = {
  description: string;
  siteUrl: string;
  title: string;
};

type Props = {
  metadata: SiteProps;
  image: string | null;
  description?: string;
  title?: string;
  url?: string;
};

const Ogp = (props: Props) => {
  const { metadata, description, image, title, url } = props;
  const { siteMetadata } = metadata;
  const siteName = siteMetadata.title;
  const siteTitle = title != null ? `${title} | ${siteName}` : siteName;
  return (
    <Helmet defaultTitle={siteName} titleTemplate={`%s | ${siteName}`}>
      {title != null && <title>{title}</title>}
      <meta name="description" content={description || siteMetadata.description} />
      <meta property="og:description" content={description || siteMetadata.description} />
      <meta property="og:image" content={`${siteMetadata.siteUrl}${image || '/img/ogp.png'}`} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:url" content={url || siteMetadata.siteUrl} />
    </Helmet>
  );
};

Ogp.defaultProps = {
  image: null
};

export const query = graphql`
  fragment OgpFragment on Site {
    siteMetadata {
      description
      siteUrl
      title
    }
  }
`;

export default Ogp;
