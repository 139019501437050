import React from 'react';
import styled from 'styled-components';
import archive from './archive.svg';

type Props = {
  alt: string;
  size: number;
};

const Archive = (props: Props) => {
  const { alt, size } = props;
  return <Img src={archive} alt={alt} width={size} height={size} />;
};

Archive.defaultProps = {
  alt: '',
  size: 32
};

const Img = styled.img`
  margin: 0;
`;

export default Archive;
