import React from 'react';
import director from './director.svg';

type Props = {
  size: number;
};

const Director = (props: Props) => {
  const { size } = props;
  return <img src={director} alt="企画・ディレクター" width={size} height={size} />;
};

Director.defaultProps = {
  size: 100
};

export default Director;
