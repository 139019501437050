import React from 'react';
import engineer from './engineer.svg';

type Props = {
  size: number;
};

const Engineer = (props: Props) => {
  const { size } = props;
  return <img src={engineer} alt="エンジニア" width={size} height={size} />;
};

Engineer.defaultProps = {
  size: 100
};

export default Engineer;
