import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import ArrowIcon from '../icons/Arrow/Arrow';
import { PRIMARY_COLOR } from '../constants/styles';

type Props = {
  isVisible: boolean;
  targetId: string;
};

type StyledProps = Pick<Props, 'isVisible'>;

const BackToTop = (props: Props) => {
  const { isVisible, targetId } = props;
  const moveTo = useRef<MoveTo>();
  const target = useRef<HTMLDivElement | null>(null);
  const unregister = useRef<MoveTo.unregisterTriggerType>(() => {});
  useEffect(() => {
    const importMoveTo = async () => {
      const { default: MoveTo } = await import('moveto');
      moveTo.current = new MoveTo();
      if (target.current != null) {
        unregister.current = moveTo.current.registerTrigger(target.current);
      }
    };
    importMoveTo();
    return () => {
      unregister.current();
    };
  }, []);
  return (
    <Button aria-hidden="true" data-target={`#${targetId}`} isVisible={isVisible} ref={target}>
      <ArrowIcon />
    </Button>
  );
};

const Button = styled.div<StyledProps>`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: ${PRIMARY_COLOR};
  cursor: pointer;
  transform: translateY(0);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export default BackToTop;
