import React from 'react';
import styled from 'styled-components';
import article from './article.svg';

type Props = {
  alt: string;
  size: number;
};
const Article = (props: Props) => {
  const { alt, size } = props;
  return <Img src={article} alt={alt} width={size} height={size} />;
};

Article.defaultProps = {
  alt: '',
  size: 32
};

const Img = styled.img`
  margin: 0;
`;

export default Article;
